type TNavigationRoute = {
    pageName: string;
    route: string;
};

export type ActiveRoutes = "Dashboard" | "AddReceipt" | "AddManualReceipt" | "CurrentMonth" |
"LastQuarter" | "CurrentYear";

const NavigationConfig: Record<ActiveRoutes, TNavigationRoute> = {
    Dashboard: { pageName: "Dashboard", route: "/dashboard" },
    AddReceipt: { pageName: "Add Receipt", route: "/dashboard/receipt" },
    AddManualReceipt: { pageName: "Add Manual Receipt", route: "/dashboard/receipt/manual" },
    CurrentMonth: { pageName: "Current month", route: "/dashboard/month" },
    LastQuarter: { pageName: "Last Quarter", route: "/dashboard/quarter" },
    CurrentYear: { pageName: "Current Year", route: "/dashboard/year" }
}

export { NavigationConfig };