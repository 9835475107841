import { AppThunk } from '../store';
import { CustomError } from './../Models/custom-error.model';
import { categoryRequest, categorySuccess, categoryError } from './category.slice';
import {getCategories} from './category.service';
import { Category, CustomResponse } from "./category.model";

export const getCategoriesEffect = (): AppThunk => async (dispatch, getState) => {
  dispatch(categoryRequest());
  try {
    const categories:Category | CustomResponse = await getCategories();
    //setTimeout(() => {
      dispatch(categorySuccess(categories));
    //}, 1000);
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(categoryError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(categoryError(error));
    }
  }
};