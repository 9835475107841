import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { TextField, TextFieldProps } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import PasswordIcon from '@mui/icons-material/Password';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import { AuthUser } from './../../Services/Auth/auth.model'
import { UserIdentity } from './../../Services/Auth/auth.types'
import { CustomError } from './../../Services/Models/custom-error.model';
import { passwordResetService } from './../../Services/Auth/auth.service';
import ThankYou from '../../Components/Common/ThankYou';

const PasswordReset: React.FunctionComponent<PasswordResetProps> = (props: PasswordResetProps) => {
  const [params, setParams] = useState(initParams);
  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState(initErrors);

  const [serverError, setServerError] = useState<string>('');

  const [success, setSuccess] = useState<boolean>(false);
  const arrayRef = useRef<Array<HTMLElement | null>>([]);

  const passwordReset = async () => {
    try {
      const result = await passwordResetService(props.token, params.password);
      setTimeout(() => {
        setIsLoading(false);
        if (!result/*.result*/) { setServerError('Something went wrong. Please try again'); }
        else setSuccess(true);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        if (error instanceof CustomError) {
          setIsLoading(false);
          setServerError(error.message);
        }
      }, 1000);
    }
  }

  const onSubmit = () => {
    if (onValidate()) {
      setIsLoading(true);
      passwordReset();
    }
  }

  const onValidate = () => {
    const errorList = Object.keys(errors);
    const paramsList = Object.keys(params);

    const checkEmpty = (x: Object) => {
      return Object.values(x).every(a => a === null || a === '');
    }

    const checkSomeEmpty = (x: Object) => {
      return Object.values(x).some(a => a === null || a === '');
    }

    if (!(params.password === params.passwordConfirm)) {
      setValidationError('New password does not match the confirm password!');
      arrayRef.current[0]?.focus();
      return false;
    };

    if ((!checkEmpty(errors)) || checkSomeEmpty(params)) {

      const firstParam = paramsList.reduce(
        (a, b) => {
          return params[a as keyof typeof params].length === 0 ? a : b
        }
      );

      const paramIndex = paramsList.indexOf(firstParam);

      const firstError = errorList.reduce(
        (a, b) => {
          return errors[a as keyof typeof errors] ? a : b
        }
      );

      const errorIndex = errorList.indexOf(firstError);

      const indexFocus = () => {
        if (paramIndex >= errorIndex) {
          return errorIndex;
        } else {
          return paramIndex;
        }
      };

      setValidationError('Please fill in the missing information');

      arrayRef.current[indexFocus()]?.focus();

      return false;
    };
    setValidationError('');
    return true;
  };

  const onTextFieldChangeHandler = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'password':
        if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value)) {
          setErrors({
            ...errors,
            password: 'Password must contain minimum eight characters, at least one letter, one number and one special character!'
          })
        } else {
          setErrors({ ...errors, password: '' })
        }
        break;
      case 'passwordConfirm':
        if (!(value === params.password)) {
          setErrors({
            ...errors,
            passwordConfirm: 'New password does not match the confirm password!'
          })
        } else {
          setErrors({ ...errors, passwordConfirm: '' })
        }
        break;
      default: {
        break;
      }
    }
    setParams({ ...params, [name]: value });
  };

  return (<>
    {success ? <ThankYou message="Password reset confirmed. Please sign in with new password." linkType='signin' /> :
      <>
        {validationError && <Alert severity="error">{JSON.stringify(validationError)}</Alert>}
        {(serverError && !validationError) &&
          <Alert severity="error">{JSON.stringify(serverError)} We were unable to send your request please try again</Alert>
        }
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <PasswordIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password reset
        </Typography>
        <TextField
          inputRef={(element) => arrayRef.current[0] = element}
          required
          margin="normal"
          fullWidth
          name="password"
          label="New password"
          type="password"
          id="password"
          autoComplete="new-password"
          onChange={onTextFieldChangeHandler()}
          color={errors.password ? 'error' : 'primary'}
          helperText={errors.password}
          disabled={isLoading}
        />
        <TextField
          inputRef={(element) => arrayRef.current[1] = element}
          required
          margin="normal"
          fullWidth
          name="passwordConfirm"
          label="Confirm password"
          type="password"
          id="passwordConfirm"
          autoComplete="new-password"
          onChange={onTextFieldChangeHandler()}
          color={errors.passwordConfirm ? 'error' : 'primary'}
          helperText={errors.passwordConfirm}
          disabled={isLoading}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onSubmit}
          disabled={isLoading}
        >
          confirm
          {isLoading && <CircularProgress size={30} color="secondary" />}
        </Button>
      </>
    }
    {!success &&
      <Grid container justifyContent="right">
        <Grid item>
          <Link href="/" variant="body2">
            Return to homepage
          </Link>
        </Grid>
      </Grid>
    }
  </>);
}

interface PasswordResetProps {
  token: UserIdentity['token'];
}

interface Errors {
  password: TextFieldProps['helperText'];
  passwordConfirm: TextFieldProps['helperText'];
}

interface Params {
  password: AuthUser['password'];
  passwordConfirm: AuthUser['password'];
}

const initParams = (): Params => ({
  password: "",
  passwordConfirm: ""
})

const initErrors = (): Errors => ({
  password: "",
  passwordConfirm: ""
})

export default PasswordReset;