import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Copyright from './../Components/Common/Copyright';

interface FooterProps {
  description: string;
  title: string;
}

const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  const { description, title } = props;

  return (
    <Box component="footer" sx={{ backgroundColor: 'primary.dark', py: 6 }}>
      <Container maxWidth="lg">
        <Typography id="contact" variant="h6" align="left" gutterBottom color="primary.contrastText">
          {title}
        </Typography>
        <Typography variant="subtitle1" align="left" color="primary.contrastText" component="p">
          {description}
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;