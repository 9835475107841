import { AppThunk } from '../store';
import { SignInParams, SignUpParams, UserIdentity, UserProfile } from './auth.types';
import { CustomError } from './../Models/custom-error.model';
import { signInRequest, signInSuccess, signInError, clearToken, getUserProfile } from './auth.slice';
import { getUserProfileService, signInService, signUpService } from './auth.service';
import { saveState, clearState } from './../Utils/local-storage';
import { signUpRequest, signUpSuccess, signUpError } from './signup.slice';


export const signInEffect = (
  email: SignInParams['email'],
  password: SignInParams['password'],
  rememberMe: SignInParams['rememberMe']
): AppThunk => async (dispatch, getState) => {
  dispatch(signInRequest());
  try {
    const userIdentity: UserIdentity = await signInService(email, password, rememberMe);
    setTimeout(() => {
      dispatch(signInSuccess(userIdentity));
      saveState(userIdentity.token); //returns true if done
      //Warning!!! Local storage is vulnerable for CSRF attacks!!!
    }, 1000);
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(signInError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(signInError(error));
    }
  }
};

export const signUpEffect = (
  firstname: SignUpParams['firstname'],
  lastname: SignUpParams['lastname'],
  email: SignUpParams['email'],
  password: SignUpParams['password']
): AppThunk => async (dispatch, getState) => {
  dispatch(signUpRequest());
  try {
    const result = await signUpService(firstname, lastname, email, password);
    if (result) {
      setTimeout(() => {
        dispatch(signUpSuccess(result));
      }, 1000);
    } else throw new CustomError('Signed up failed. Please try again.', 500, '');
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(signUpError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(signUpError(error));
    }
  }
};

export const logOutEffect = (): AppThunk => async (dispatch, getState) => {
  clearState();
  dispatch(clearToken());
};

export const getUserProfileEffect = (): AppThunk => async (dispatch, getState) => {
  try {
    const userProfile: UserProfile = await getUserProfileService();
    setTimeout(() => {
      dispatch(getUserProfile(userProfile));
    }, 1000);
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(signInError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(signInError(error));
    }
  }
};