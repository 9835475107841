import { createSlice } from "@reduxjs/toolkit";
import { AddManualReceiptState } from "./receipt.types";

const initialState: AddManualReceiptState = {
    manualReceipt: null,
    isLoading: false,
    error: ""
};

const addManualReceiptSlice = createSlice({
    name: "addManualReceipt",
    initialState,
    reducers: {
        addManualReceiptRequest(state) {
            state.manualReceipt = null;
            state.isLoading = true;
            state.error = "";
        },
        addManualReceiptSuccess(state, action) {
            state.manualReceipt = action.payload;
            state.isLoading = false;
            state.error = "";
        },
        addManualReceiptError(state, action) {
            state.manualReceipt = null;
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { addManualReceiptRequest, addManualReceiptSuccess, addManualReceiptError }
    = addManualReceiptSlice.actions;
export default addManualReceiptSlice.reducer;