//Warning!!! Local storage is vulnerable for CSRF attacks!!!

const loadState = (): string | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    const localState = localStorage.getItem("token");
    if (localState == null) {
      return;
    }
    return localState;
  } catch (error) {
    throw new Error('local storage load failed');
  }
}

const saveState = (storeState: string): boolean => {
  if (!localStorage) {
    return false;
  }
  try {
    localStorage.setItem("token", storeState);
    return true;
  } catch (error) {
    throw new Error('save local storage failed');
  }
}

const clearState = () => {
    localStorage.removeItem("token");
}

export { loadState, saveState, clearState };