import { createSlice } from "@reduxjs/toolkit";
import { CategoryState } from "./category.types";

const initialState: CategoryState = {
    categories: [],
    isLoading: false,
    error: ""
};

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        categoryRequest(state) {
            state.categories = [];
            state.error = "";
            state.isLoading = true;
        },
        categorySuccess(state, action) {
            state.categories = action.payload;
            state.isLoading = false;
            state.error = "";
        },
        categoryError(state, action) {
            state.categories = [];
            state.error = action.payload;
            state.isLoading = false;
        }
    },
});

export const { categoryRequest, categorySuccess, categoryError } = categorySlice.actions;
export default categorySlice.reducer;