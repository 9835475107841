import React from 'react';
import DashboardContent from './DashboardContent';
import AddReceipt from './AddReceipt';
import AddManualReceipt from './AddManualReceipt';
import dayjs from 'dayjs';
import { NavigationConfig, ActivePage } from './../../Services/Utils/navigation';

const DashboardNavigation: React.FunctionComponent<DashboardNavigationProps> = (props: DashboardNavigationProps) => {

  const ActiveComponent = NavigationConfig[ActivePage(props.location)];
  const quarterStart = dayjs().startOf("quarter").subtract(1, 'quarter'),
    quarterEnd = dayjs().endOf("quarter").subtract(1, 'quarter');

  switch (ActiveComponent) {
    case NavigationConfig.Dashboard: {
      return <DashboardContent key="dashboard" dateFrom={dayjs().startOf('week')} dateTo={dayjs()} />;
    }
    case NavigationConfig.AddReceipt: {
      return <AddReceipt />;
    }
    case NavigationConfig.AddManualReceipt: {
      return <AddManualReceipt />;
    }
    case NavigationConfig.CurrentMonth: {
      return <DashboardContent key="currentMonth" dateFrom={dayjs().startOf('month')} dateTo={dayjs().endOf('month')} hideSearchBar={true} />;
    }
    case NavigationConfig.LastQuarter: {
      return <DashboardContent key="lastQuarter" dateFrom={quarterStart} dateTo={quarterEnd} hideSearchBar={true} />;
    }
    case NavigationConfig.CurrentYear: {
      return <DashboardContent key="currentYear" dateFrom={dayjs().startOf('year')} dateTo={dayjs().endOf('year')} hideSearchBar={true} />;
    }
    default: {
      return <> </>;
    }
  }
}

const DashboardNavigationPageName: React.FunctionComponent<DashboardNavigationPageNameProps> =
  (props: DashboardNavigationPageNameProps) => {
    return <>{NavigationConfig[ActivePage(props.location)].pageName}</>
  }

interface DashboardNavigationProps {
  location: string;
}

interface DashboardNavigationPageNameProps {
  location: string;
}


export { DashboardNavigation, DashboardNavigationPageName };