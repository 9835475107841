import React from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';

const ThankYou: React.FunctionComponent<IThankYouProps> = (props: IThankYouProps) => {

  const avatar = (): JSX.Element => {
    if (props.messageType === 'success') {
      return (<Avatar sx={{ m: 1, bgcolor: 'green' }}><CheckCircleIcon /></Avatar>)
    };
    if (props.messageType === 'error') {
      return (<Avatar sx={{ m: 1, bgcolor: 'red' }}><ErrorIcon /></Avatar>)
    };
    return (<Avatar sx={{ m: 1, bgcolor: 'green' }}><CheckCircleIcon /></Avatar>)
  }

  const link = (): JSX.Element => {
    if (props.linkType === 'home') {
      return (<Link href="/" variant="body2">Return to homepage</Link>)
    };
    if (props.linkType === 'signin') {
      return (<Link href="/signin" variant="body2">Proceed to sign in</Link>)
    };
    return (<Link href="/" variant="body2">Return to homepage</Link>)
  }

  return (<>
    {avatar()}
    <Typography component="h1" variant="h5">{props.message}</Typography>
    <Box sx={{ mt: 3 }}>
      <Grid container justifyContent="flex-end">
        {link()}
      </Grid>
    </Box>
  </>);
}

type MessageType = 'success' | 'error';
type LinkType = 'signin' | 'home';

interface IThankYouProps {
  message: string;
  messageType?: MessageType;
  linkType?: LinkType;
}

export default ThankYou;