import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { TextField, TextFieldProps } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import { SignInParams } from './../../Services/Auth/auth.types';
import { signInEffect } from './../../Services/Auth/auth.effects'
import { useAppDispatch, useAppSelector } from './../../Services/Hooks/hooks';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const SignIn: React.FunctionComponent = () => {
  const [params, setParams] = useState(initParams);
  const [validationError, setValidationError] = useState('');
  const [errors, setErrors] = useState(initErrors);
  const arrayRef = useRef<Array<HTMLElement | null>>([]);
  const signInState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    if (onValidate()) {
      dispatch(signInEffect(params.email, params.password, params.rememberMe));
    }
  }

  const onValidate = () => {
    const errorList = Object.keys(errors);
    let { rememberMe, ...newParams } = params; // exclude checkbox boolean parameter from checking
    const paramsList = Object.keys(params);

    const checkEmpty = (x: Object) => {
      return Object.values(x).every(a => a === null || a === '');
    }

    const checkSomeEmpty = (x: Object) => {
      return Object.values(x).some(a => a === null || a === '');
    }

    if ((!checkEmpty(errors)) || checkSomeEmpty(newParams)) {

      const firstParam = paramsList.reduce(
        (a, b) => {
          return newParams[a as keyof typeof newParams].length === 0 ? a : b
        }
      );

      const paramIndex = paramsList.indexOf(firstParam);

      const firstError = errorList.reduce(
        (a, b) => {
          return errors[a as keyof typeof errors] ? a : b
        }
      );

      const errorIndex = errorList.indexOf(firstError);

      const indexFocus = () => {
        if (paramIndex >= errorIndex) {
          return errorIndex;
        } else {
          return paramIndex;
        }
      };

      setValidationError('Please fill in the missing information');

      arrayRef.current[indexFocus()]?.focus();

      return false;
    };
    setValidationError('');
    return true;
  };

  const onTextFieldChangeHandler = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'email':
        if (value.length < 1) {
          setErrors({ ...errors, email: 'Email required!' })
        } else {
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setErrors({ ...errors, email: 'Email is not valid!' })
          } else {
            setErrors({ ...errors, email: '' })
          }
        }
        break;
      case 'password':
        if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value)) {
          setErrors({
            ...errors,
            password: 'Password must contain minimum eight characters, at least one letter, one number and one special character!'
          })
        } else {
          setErrors({ ...errors, password: '' })
        }
        break;
      default: {
        break;
      }
    }
    (event.target.type === 'checkbox') ?
      setParams({ ...params, [name]: event.target.checked }) :
      setParams({ ...params, [name]: value });
  };

  return (<>
    {validationError ? (<Alert severity="error">{JSON.stringify(validationError)}</Alert>) : <></>}
    {(signInState.error && !validationError) ? (
      <Alert severity="error">{JSON.stringify(signInState.error)} We were unable to sign you in please try again</Alert>
    ) : <></>}
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Sign in
    </Typography>
    <Box sx={{ mt: 3 }}>
      <TextField
        inputRef={(element) => arrayRef.current[0] = element}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        onChange={onTextFieldChangeHandler()}
        color={errors.email ? 'error' : 'primary'}
        helperText={errors.email}
        disabled={signInState.isLoading}
      />
      <TextField
        margin="normal"
        inputRef={(element) => arrayRef.current[1] = element}
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        onChange={onTextFieldChangeHandler()}
        color={errors.password ? 'error' : 'primary'}
        helperText={errors.password}
        disabled={signInState.isLoading}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={params.rememberMe}
            name="rememberMe"
            id="rememberMe"
            color="primary"
            onChange={onTextFieldChangeHandler()}
          />}
        label="Remember me"
      />
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={onSubmit}
        disabled={signInState.isLoading}
      >
        Sign In
        {signInState.isLoading && <CircularProgress size={30} color="secondary" />}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="/password-reset-request" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href="/signup" variant="body2">
            "Don't have an account? Sign Up"
          </Link>
        </Grid>
      </Grid>
    </Box>
  </>);
}

interface Errors {
  email: TextFieldProps['helperText'];
  password: TextFieldProps['helperText'];
}

const initParams = (): SignInParams => ({
  email: "",
  password: "",
  rememberMe: false
})

const initErrors = (): Errors => ({
  email: "",
  password: ""
})

export default SignIn;