import React from 'react';
import { useTheme } from '@mui/material/styles';
import { BarChart, CartesianGrid, Bar, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useAppSelector } from './../../Services/Hooks/hooks';
import dayjs from 'dayjs';
import Title from './Title';

const Chart: React.FunctionComponent = () => {
  const theme = useTheme();
  const receiptState = useAppSelector((state) => state.receipt);

  interface ChartData {
    issueDate: string;
    totalAmount: number;
  }

  let data: Array<ChartData> = [];

  Object.values(receiptState.receipts).map((receipt) => (
    data.push(
      {
        issueDate: dayjs(receipt.issueDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
        totalAmount: receipt.totalPrice
      })
  ));

  const groupByDate = (data: Array<ChartData>) => {

    const roundNum = (n: number) => {
      return Math.round((n + Number.EPSILON) * 100) / 100
    }

    if (data.length === 0) return []; // data is loading
    if (data.length === 1) return [data[0]]; // if array has only one element

    let dataGrouped: Array<ChartData> = []; // resulting array grouped by date
    let pushEnabled: boolean = true; // trigger for pushing a totals into a new array
    let tempTotal: number = data[0].totalAmount; // totals variable

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].issueDate === data[i + 1].issueDate) { //compare two neighbor elements
        tempTotal = tempTotal + data[i + 1].totalAmount;
        pushEnabled = false;
        if (i === (data.length - 2)) { dataGrouped.push({ issueDate: data[i].issueDate, totalAmount: tempTotal }); }
      }
      else {
        if (pushEnabled) { dataGrouped.push(data[i]) }
        else {
          dataGrouped.push({ issueDate: data[i].issueDate, totalAmount: tempTotal });
        }
        tempTotal = data[i + 1].totalAmount;
        pushEnabled = true;
        if (i === (data.length - 2)) { dataGrouped.push(data[i + 1]); };
      }
    }

    for (let i = 0; i < dataGrouped.length - 1; i++) {
      dataGrouped[i].totalAmount = roundNum(dataGrouped[i].totalAmount);
    }

    return dataGrouped;
  }

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <div style={{ display: "inline-block", padding: 10, background: theme.palette.background.paper }}>
            <p className="label">{`${label}`}</p>
            <p className="label" style={{ color: theme.palette.primary.dark }}>{`${payload?.[0].value} €`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {!receiptState.isLoading &&
        <>
          <Title>Spendings per day, €</Title>
          <ResponsiveContainer>
            <BarChart data={groupByDate(data)} margin={{ top: 16, right: 16, bottom: 0, left: 24 }}>
              <Tooltip content={<CustomTooltip />} />
              <XAxis
                interval="preserveStartEnd"
                dataKey="issueDate"
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <YAxis stroke={theme.palette.text.secondary} style={theme.typography.body2}>
                <Label angle={270} position="left"
                  style={{
                    textAnchor: 'middle',
                    fill: theme.palette.text.primary,
                    ...theme.typography.body1,
                  }}
                >
                  Spendings (€)
                </Label>
              </YAxis>
              <CartesianGrid stroke={theme.palette.primary.main} />
              <Bar
                isAnimationActive={true}
                type="monotone"
                dataKey="totalAmount"
                stroke={theme.palette.text.secondary}
                fill={theme.palette.primary.main}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      }
    </>
  );
}

export default Chart;