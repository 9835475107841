import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Legend, Cell, PieChart, Pie, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useAppSelector } from './../../Services/Hooks/hooks';
import Title from './Title';

const SpendingsChart: React.FunctionComponent = () => {
  const theme = useTheme();
  const receiptState = useAppSelector((state) => state.receipt);

  interface ChartData {
    categoryId: number,
    categoryName: string;
    totalAmount: number;
  }

  let data: Array<ChartData> = [];

  Object.values(receiptState.receipts).map(
    (receipt) => (
      Object.values(receipt.items).map(
        (items) => (
          data.push(
            {
              categoryId: (items.category_id ? items.category_id : 0),
              categoryName: (items.category?.name ? items.category?.name : "No category"),
              totalAmount: items.price
            }
          )
        )
      )
    )
  );

  const groupByDate = (data: Array<ChartData>) => {

    interface GroupedData {
      [key: string]: ChartData[]; // CategoryName: [array of total amounts]
    }

    if (data.length === 0) return []; // data is loading
    if (data.length === 1) return [data[0]]; // if array has only one element

    let chartData: Array<ChartData> = []; // resulting array grouped by date

    let groupedData: GroupedData = data.reduce(
      (result: any, currentValue: any) => {
        (result[currentValue['categoryName']] = result[currentValue['categoryName']] || []).push(currentValue);
        return result;
      }, {});

    Object.entries(groupedData).forEach(([key, value]) => {
      chartData.push(
        {
          categoryId: value[0].categoryId,
          categoryName: key,
          totalAmount:
            Math.round((value.reduce((prev, current) => { return prev + current.totalAmount }, 0) + Number.EPSILON) * 100) / 100

        }
      )
    });

    return chartData;
  }

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <div style={{ display: "inline-block", padding: 10, background: theme.palette.grey[100] }}>
            <p className="label">{`${payload?.[0].name}`}</p>
            <p className="label" style={{ color: theme.palette.primary.dark }}>{`${payload?.[0].value} €`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  //const COLORS = ['#2019d4', '#0053eb', '#0077f3', '#0095f0', '#25b0e8', '#86c9e1', '#cadde2',
   // '#a7d5d7', '#85cbc4', '#69c1a9', '#5ab587', '#59a860', '#609934'];

  const COLORS = ['#1976d2','#0d6ac8','#015dbe','#0051b3','#0045a8','#00399d','#002c92','#001f86',
  '#00117a','#1d1c88','#302796','#4032a4','#4f3eb2','#6e54bd','#8a6ac8','#a382d4','#bb9be0',
  '#d1b4ec','#e7cef9','#d8b3ef','#c998e4','#bb7dd9','#ae61cd','#a041c0','#9315b2']; //25 colors
  //https://www.learnui.design/tools/data-color-picker.html#divergent  

  const chartData = groupByDate(data);

  return (
    <>
      {!receiptState.isLoading &&
      <>
        <Title>Spendings per category, €</Title>
        <ResponsiveContainer>
          <PieChart width={730} height={350} margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
            <Tooltip content={<CustomTooltip />} />
            <Pie
              data={chartData}
              dataKey="totalAmount"
              nameKey="categoryName"
              cx="50%"
              cy="50%"
              startAngle={-270}

              outerRadius={100}
              fill={theme.palette.primary.main}
              label>
              {
                chartData.map((entry, index) =>
                  <Cell
                    key={entry.categoryName}
                    fill={COLORS[entry.categoryId]}
                  />)
              }
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
        </>
      }
    </>
  );
}

export default SpendingsChart;