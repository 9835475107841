import React, { useState, useEffect } from 'react';
import { activateAccountService } from './../../Services/Auth/auth.service';
import { CustomError } from './../../Services/Models/custom-error.model';
import { CircularProgress } from '@mui/material';
import ThankYou from './../../Components/Common/ThankYou';
import { UserIdentity } from './../../Services/Auth/auth.types'

const ActivateAccount: React.FunctionComponent<ActivateAccountProps> = (props: ActivateAccountProps) => {

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {

    const activateAccount = async () => {
      try {
        const result = await activateAccountService(props.token);
        setIsLoading(false);
        if (!result.result) { setError('Something went wrong. Please try again'); }
      } catch (error) {
        if (error instanceof CustomError) {
          setIsLoading(false);
          setError(error.message);
        }
      }
    }

    activateAccount();

  }, [props.token]);

  return (<>
    {isLoading && <CircularProgress size={30} color="secondary" />}
    {!isLoading &&
      (<>
        {error ?
          (<ThankYou message={error} messageType='error' />) :
          (<ThankYou message="Thank you. Your account has been activated." linkType='signin' />)
        }
      </>)
    }
  </>);
}

interface ActivateAccountProps {
  token: UserIdentity['token'];
}

export default ActivateAccount;