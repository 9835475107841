import instance from './../../Config/axios.config';
import { Receipt, CustomResponse } from './receipt.model';
import { ReceiptParams, Receipts, AddReceiptParams, AddManualReceiptParams } from './receipt.types';
import { CustomError } from '../Models/custom-error.model';

export const getReceiptByDate = async (from: ReceiptParams['from'], to: ReceiptParams['to']) => {
  try {
    const url = `/receipts/date?from=${from}&to=${to}`;
    const { data } = await instance.get<Receipts | CustomResponse>(url);
    if ('message' in data) {
      throw new CustomError(data.message, 200);
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const addReceipt = async (receiptId: AddReceiptParams['receiptId']) => {
  const url = `/receipts`;
  const params = { receiptId: receiptId };
  const { data } = await instance.post<Receipt>(url, params);
  return data;
};

export const addManualReceipt = async (receipt: AddManualReceiptParams) => {

  const url = `/receipts/manual`;
  const params = () => {
    return receipt.issueDate && {
      issueDate: receipt.issueDate.format('DD-MM-YYYY'),
      totalPrice: receipt.totalPrice,
      items: [{ name: receipt.itemName, category_id: receipt.category_id }]
    }
  };
  const { data } = await instance.post<Receipt>(url, params());
  return data;
};