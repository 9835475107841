import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from './../../Layouts/Header';
import FeaturedBanner from './../../Components/Common/FeaturedBanner';
import Main from './../../Layouts/Main';
import Footer from './../../Layouts/Footer';
import { useLocation } from 'react-router-dom';
import homeMD from './../../Assets/Posts/home.md';
import policyMD from './../../Assets/Posts/policy.md';

const sections = [
  { title: 'Home', url: '/' },
  { title: 'Privacy Policy', url: '/policy' },
  { title: 'Contact us', url: '#contact' }
];

const featuredBanner = {
  title: 'Categorise and analyse your expenses',
  description:
    "Save your receipts to control your expenses.",
  image: 'https://picsum.photos/1200/300',
  imageText: 'Categorise and analyse your expenses',
  linkText: 'Sign up'
};

const Home: React.FunctionComponent = () => {

  const location = useLocation().pathname;

  return (
    <>
      <Container maxWidth="lg">
        <Header title="Receipt Researcher" sections={sections} />
        <main>
          {!(location === '/policy') && <FeaturedBanner post={featuredBanner} />}
          <Grid container spacing={5} sx={{ mt: 3 }}>
            {(location === '/policy') ? <Main title="Privacy Policy" post={policyMD} /> :
              <Main title="Categorise and analyse your expenses" post={homeMD} />}
          </Grid>
        </main>
      </Container>
      <Footer title="Contact us" description="contact@yanpix.com" />
    </>
  );
}

export default Home;