import { createSlice } from "@reduxjs/toolkit";
import { SignUpState } from "./auth.types";

const initialState: SignUpState = {
    isSignedUp: false,
    isLoading: false,
    error: ""
};

const signUpSlice = createSlice({
    name: "signUp",
    initialState,
    reducers: {
        signUpRequest(state) {
            state.isLoading = true;
            state.error = "";
        },
        signUpSuccess(state, action) {
            state.isLoading = false;
            state.isSignedUp = action.payload;
            state.error = "";
        },
        signUpError(state, action) {
            state.isSignedUp = false;
            state.isLoading = false;
            state.error = action.payload;
        }
    },
});

export const { signUpRequest, signUpError, signUpSuccess } = signUpSlice.actions;
export default signUpSlice.reducer;