import { configureStore, ThunkAction, Action, combineReducers, AnyAction, Reducer } from '@reduxjs/toolkit';
import authReducer from './Auth/auth.slice';
import signUpReducer from './Auth/signup.slice';
import receiptReducer from './Receipt/receipt.slice';
import categoryReducer from './Category/category.slice';
import addReceiptReducer from './Receipt/add.receipt.slice';
import addManualReceiptReducer from './Receipt/add.manual.receipt.slice';
import loggerMiddleware from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

const appReducer = combineReducers({
  auth: authReducer,
  signUp: signUpReducer,
  receipt: receiptReducer,
  category: categoryReducer,
  addReceipt: addReceiptReducer,
  addManualReceipt: addManualReceiptReducer
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/clearToken') {
    state = {} as RootState
  }
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  (
    (process.env.NODE_ENV === 'production') ?
      getDefaultMiddleware().concat([thunkMiddleware]) :
      getDefaultMiddleware().concat([thunkMiddleware, loggerMiddleware])
  )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appReducer>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;