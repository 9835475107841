import { createSlice } from "@reduxjs/toolkit";
import { ReceiptState } from "./receipt.types";

const initialState: ReceiptState = {
    receipts: [],
    isLoading: false,
    error: ""
};

const receiptSlice = createSlice({
    name: "receipt",
    initialState,
    reducers: {
        receiptRequest(state) {
            state.receipts = [];
            state.error = "";
            state.isLoading = true;
        },
        receiptSuccess(state, action) {
            state.receipts = action.payload;
            state.isLoading = false;
            state.error = "";
        },
        receiptError(state, action) {
            state.receipts = [];
            state.error = action.payload;
            state.isLoading = false;
        }
    },
});

export const { receiptRequest, receiptSuccess, receiptError } = receiptSlice.actions;
export default receiptSlice.reducer;