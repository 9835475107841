import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { indigo } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import AppRoutes from './../Routes/Routes';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dayjsSetup } from './../Config/dayjs.helper';

// import { DATA_SOURCES } from './../../config/vars.config';
// const dataSource = DATA_SOURCES.mySqlDataSource;
// const port = process.env.API_PORT;

const theme = createTheme(
  // {
  //   palette: {
  //     primary: indigo,
  //     secondary: {
  //       main: '#01579b'
  //     }
  //   }
  // }
);

function App() {
  dayjsSetup();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'/*adapterLocale="sk"*/>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;