import { createSlice } from "@reduxjs/toolkit";
import { AddReceiptState } from "./receipt.types";

const initialState: AddReceiptState = {
    receiptId: '',
    isLoading: false,
    error: ""
};

const addReceiptSlice = createSlice({
    name: "addReceipt",
    initialState,
    reducers: {
        addReceiptRequest(state) {
            state.receiptId = "";
            state.isLoading = true;
            state.error = "";
        },
        addReceiptSuccess(state, action) {
            state.receiptId = action.payload;
            state.isLoading = false;
            state.error = "";
        },
        addReceiptError(state, action) {
            state.receiptId = "";
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { addReceiptRequest, addReceiptSuccess, addReceiptError } = addReceiptSlice.actions;
export default addReceiptSlice.reducer;