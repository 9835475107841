import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import QrCodeReader, { QRCode } from 'react-qrcode-reader';
import { TextField, TextFieldProps } from '@mui/material';
import { addReceiptEffect } from './../../Services/Receipt/receipt.effects';
import { useAppDispatch, useAppSelector } from './../../Services/Hooks/hooks';
import ShowAlert from './../Common/ShowAlert';
import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/material';

const AddReceipt: React.FunctionComponent = () => {
  const [QRCodeValue, setQRCodeValue] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const addReceiptData = useAppSelector((state) => state.addReceipt);
  const [show, setShow] = useState<boolean>(false);
  const [color, setColor] = useState<TextFieldProps['color']>('primary');
  const [helperText, setHelperText] = useState<TextFieldProps['helperText']>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const handleRead = (code: QRCode) => {
    setColor('success');
    setHelperText('Congratulations! QR code scanned successfully!');
    setQRCodeValue(code.data);
  };

  const onValidate = () => {
    if (!QRCodeValue) {
      setValidationError('Receipt UID code required');
      return false;
    } else if (!/[A-Z0-9]+-[A-Z0-9]/i.test(QRCodeValue)) {
      setValidationError('Invalid Receipt UID code provided');
      return false;
    }
    setValidationError('');
    return true;
  };

  const onSubmit = () => {
    setShow(true);
    if (onValidate()) {
      dispatch(addReceiptEffect(QRCodeValue));
    };
  };

  const onUpdate = () => {
    setColor('primary');
    setHelperText('');
    inputRef.current?.focus();
    setShow(false);
  }

  //<QrCodeReader delay={100} width={300} height={300} onRead={handleRead} />
  //<Box sx={{ width: 300, height: 225, bgcolor: 'cyan', m: 5 }}></Box>
  return (
    <Box
      sx={{
        m: 4,
        display: 'flex',
        flexDirection: { xs: 'column' },
        maxWidth: 500,
        maxHeight: 'sm',
        alignItems: 'center',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        borderRadius: '12px',
        boxShadow: 1,
        fontWeight: 'bold',
        position: "relative",
        paddingTop: '40px'
      }}>

      {show ?
        <Stack sx={{ width: '100%' }} spacing={8}>
          {validationError ? <ShowAlert onUpdate={onUpdate} show={show} severity="error" message={JSON.stringify(validationError)} /> : (
            <>
              {addReceiptData.receiptId ? <ShowAlert onUpdate={onUpdate} show={show} severity="success" message="Success! Receipt created!" /> : <></>}
              {addReceiptData.error ? <ShowAlert onUpdate={onUpdate} show={show} severity="error" message={addReceiptData.error} /> : <></>}
            </>
          )}
        </Stack>
        : <></>}

      <QrCodeReader delay={200} width={300} height={300} onRead={handleRead} />
      <TextField
        inputRef={inputRef}
        margin="normal"
        key="textQRCode"
        required
        value={QRCodeValue}
        id="receiptId"
        label="Receipt UID"
        name="receiptId"
        autoComplete="receiptId"
        autoFocus
        sx={{ width: 400 }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setQRCodeValue(event.target.value);
        }}
        color={color}
        helperText={helperText}
        disabled={addReceiptData.isLoading}
      />
      <Button type="button" variant="contained"
        disabled={addReceiptData.isLoading}
        onClick={onSubmit}
        sx={{ mt: 4, mb: 5 }}>
        Add Receipt
        {addReceiptData.isLoading && <CircularProgress size={30} color="secondary" />}
      </Button>
    </Box >
  )
};

export default AddReceipt;