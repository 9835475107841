import React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { useAppSelector } from './../../Services/Hooks/hooks';
import dayjs from 'dayjs';


const Totals: React.FunctionComponent = () => {
  const receiptState = useAppSelector((state) => state.receipt);

  const totalSummary = receiptState.receipts.reduce((prev, current) => {
    return prev + current.totalPrice;
  }, 0).toFixed(2);

  const startDate = receiptState.receipts[0] &&
    dayjs(receiptState.receipts[0].issueDate, "DD-MM-YYYY").format("DD-MM-YYYY");

  const endDate = receiptState.receipts[receiptState.receipts.length-1] &&
    dayjs(receiptState.receipts[receiptState.receipts.length-1].issueDate, "DD-MM-YYYY").format("DD-MM-YYYY");

  return (
    <>
      {!receiptState.isLoading &&
        <>
          <Title>Totals</Title>
          <Typography component="p" variant="h4">
            {totalSummary}€
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            from {startDate} to {endDate}
          </Typography>
        </>
      }
    </>
  );
}

export default Totals;
