import { ActiveRoutes, NavigationConfig } from './../../Types/navigation.types'

const ActivePage = (location: string): ActiveRoutes => {

  const removeTrailingSlash = (str: string) => {
      return str.replace(/\/+$/, '');
  }

  let path = removeTrailingSlash(location);

  const key = Object.keys(NavigationConfig).find(key => NavigationConfig[key as ActiveRoutes].route === path);

  return key as ActiveRoutes;
}

export { ActivePage, NavigationConfig };