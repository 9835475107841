import React, { useEffect, useState } from 'react';
import Grid2 from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from './../Components/Common/Markdown';

interface MainProps {
  post: string;
  title: string;
}

const Main: React.FunctionComponent<MainProps> = (props: MainProps) => {
  const { title, post } = props;
  const [postMarkdown, setPostMarkdown] = useState<string>('');

  useEffect(() => {
    fetch(post)
      .then((response) => response.text())
      .then((text) => {
        setPostMarkdown(text);
      });
  }, [post]);

  return (
    <Grid2 item xs={12} md={8} sx={{ '& .markdown': { py: 3, }, }}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Divider />
      <Markdown className="markdown" key={postMarkdown.substring(0, 40)}>
        {postMarkdown}
      </Markdown>
    </Grid2>
  );
};

export default Main;
