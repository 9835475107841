import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
  }>;
  title: string;
}

const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { sections, title } = props;

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Typography component="h2" variant="h5" color="inherit" align="left" noWrap sx={{ flex: 1 }}>
          {title}
        </Typography>
        <Button href="/signin" variant="outlined" size="small" sx={{ mr: 2 }} >Sign in</Button>
        <Button href="/signup" variant="outlined" size="small">Sign up</Button>
      </Toolbar>
      <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'left', overflowX: 'auto' }}>
        {sections.map((section) => (
          <Link color="inherit" noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            sx={{ p: 1, flexShrink: 0 }}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar>
    </>
  );
};

export default Header;