import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, TextField, TextFieldProps } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import { SignUpParams } from './../../Services/Auth/auth.types';
import { signUpEffect } from './../../Services/Auth/auth.effects'
import { useAppDispatch, useAppSelector } from './../../Services/Hooks/hooks';

const SignUp: React.FunctionComponent = () => {
  const [params, setParams] = useState(initParams);
  const [validationError, setValidationError] = useState('');
  const [errors, setErrors] = useState(initErrors);
  const arrayRef = useRef<Array<HTMLElement | null>>([]);
  const signUpState = useAppSelector((state) => state.signUp);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    if (onValidate()) {
      //disable for new users registrations
      //dispatch(signUpEffect(params.firstname, params.lastname, params.email, params.password));
    }
  }

  const onValidate = () => {
    const errorList = Object.keys(errors);
    let { policy, ...newParams } = params; // exclude checkbox boolean parameter from checking
    const paramsList = Object.keys(params);

    const checkEmpty = (x: Object) => {
      return Object.values(x).every(a => a === null || a === '');
    }

    const checkSomeEmpty = (x: Object) => {
      return Object.values(x).some(a => a === null || a === '');
    }

    if ((!checkEmpty(errors)) || checkSomeEmpty(newParams)) {

      const firstParam = paramsList.reduce(
        (a, b) => {
          return newParams[a as keyof typeof newParams].length === 0 ? a : b
        }
      );

      const paramIndex = paramsList.indexOf(firstParam);

      const firstError = errorList.reduce(
        (a, b) => {
          return errors[a as keyof typeof errors] ? a : b
        }
      );

      const errorIndex = errorList.indexOf(firstError);

      const indexFocus = () => {
        if (paramIndex >= errorIndex) {
          return errorIndex;
        } else {
          return paramIndex;
        }
      };

      setValidationError('Please fill in the missing information');

      arrayRef.current[indexFocus()]?.focus();

      return false;
    };
    if (!params.policy) { setValidationError('Please accept the privacy policy'); return false; }
    setValidationError('');
    return true;
  };

  const onTextFieldChangeHandler = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'firstname':
        if (value.length < 2) {
          setErrors({ ...errors, firstname: 'First name must be 2 characters long!' })
        } else {
          if (!/^[a-z ,.'-]+$/i.test(value)) {
            setErrors({ ...errors, firstname: 'First name contains invalid characters!' })
          } else {
            setErrors({ ...errors, firstname: '' })
          }
        }
        break;
      case 'lastname':
        if (value.length < 2) {
          setErrors({ ...errors, lastname: 'Last name must be 2 characters long!' })
        } else {
          if (!/^[a-z ,.'-]+$/i.test(value)) {
            setErrors({ ...errors, lastname: 'Last name contains invalid characters!' })
          } else {
            setErrors({ ...errors, lastname: '' })
          }
        }
        break;
      case 'email':
        if (value.length < 1) {
          setErrors({ ...errors, email: 'Email required!' })
        } else {
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setErrors({ ...errors, email: 'Email is not valid!' })
          } else {
            setErrors({ ...errors, email: '' })
          }
        }
        break;
      case 'password':
        if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value)) {
          setErrors({
            ...errors,
            password: 'Password must contain minimum eight characters, at least one letter, one number and one special character!'
          })
        } else {
          setErrors({ ...errors, password: '' })
        }
        break;
      default: {
        break;
      }
    }
    (event.target.type === 'checkbox') ?
      setParams({ ...params, [name]: event.target.checked }) :
      setParams({ ...params, [name]: value });
  };

  return (<>
    {validationError ? (<Alert severity="error">{JSON.stringify(validationError)}</Alert>) : <></>}
    {(signUpState.error && !validationError) ? (
      <Alert severity="error">{JSON.stringify(signUpState.error)} We were unable to sign you up please try again</Alert>
    ) : <></>}
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Sign up
    </Typography>
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={(element) => arrayRef.current[0] = element}
            key="firstName"
            autoComplete="given-name"
            name="firstname"
            onChange={onTextFieldChangeHandler()}
            required
            fullWidth
            id="firstname"
            label="First Name"
            autoFocus
            color={errors.firstname ? 'error' : 'primary'}
            helperText={errors.firstname}
            disabled={signUpState.isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            key="lastName"
            inputRef={(element) => arrayRef.current[1] = element}
            onChange={onTextFieldChangeHandler()}
            required
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="family-name"
            color={errors.lastname ? 'error' : 'primary'}
            helperText={errors.lastname}
            disabled={signUpState.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={(element) => arrayRef.current[2] = element}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={onTextFieldChangeHandler()}
            color={errors.email ? 'error' : 'primary'}
            helperText={errors.email}
            disabled={signUpState.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={(element) => arrayRef.current[3] = element}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={onTextFieldChangeHandler()}
            color={errors.password ? 'error' : 'primary'}
            helperText={errors.password}
            disabled={signUpState.isLoading}
          />
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={params.policy}
            name="policy"
            id="policy"
            color="primary"
            onChange={onTextFieldChangeHandler()}
          />}
        label="I consent with processing of data according to"
      />
      <Link href="/policy" target="_blank" variant="body2">
        privacy policy
      </Link>
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={onSubmit}
        disabled={signUpState.isLoading}
      >
        Sign Up
        {signUpState.isLoading && <CircularProgress size={30} color="secondary" />}
      </Button>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link href="/signin" variant="body2">
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </Box>
  </>);
}

interface Errors {
  firstname: TextFieldProps['helperText'];
  lastname: TextFieldProps['helperText'];
  email: TextFieldProps['helperText'];
  password: TextFieldProps['helperText'];
}

const initParams = (): SignUpParams => ({
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  policy: false
})

const initErrors = (): Errors => ({
  firstname: "",
  lastname: "",
  email: "",
  password: ""
})

export default SignUp;