import instance from './../../Config/axios.config';
import { Category, CustomResponse } from "./category.model";
import { CustomError } from '../Models/custom-error.model';

export const getCategories = async () => {
  try {
    const url = `/categories`;
    const { data } = await instance.get<Category | CustomResponse>(url);
    if ('message' in data) {
      throw new CustomError(data.message, 200);
    }
    return data;
  } catch (error) {
    throw error;
  }
};