import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import quarterOfYear from "dayjs/plugin/quarterOfYear";
//import 'dayjs/locale/sk';
// https://github.com/iamkun/dayjs/tree/dev/src/locale
//import utc from 'dayjs/plugin/utc';

export const dayjsSetup = () => {
  dayjs.locale('en-gb');
  //setup plugins here
  dayjs.extend(quarterOfYear);
  //dayjs.extend(utc)
}