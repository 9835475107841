import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./auth.types";
import { loadState } from "./../Utils/local-storage"

const persistedState = loadState();
//Warning!!! Local storage is vulnerable for CSRF attacks!!!

const isAuth = (): boolean => {
    if (persistedState == null) {
        return false
    }
    return true;
};

const initialState: AuthState = {
    identity: {
        firstname: "",
        lastname: "",
        token: persistedState as string
    },
    isAuthenticated: isAuth(),
    isLoading: false,
    error: ""
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signInRequest(state) {
            state.isLoading = true;
            state.error = "";
        },
        signInSuccess(state, action) {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.identity = action.payload;
            state.error = "";
        },
        signInError(state, action) {
            state.isAuthenticated = false;
            state.isLoading = false;
            state.identity = { firstname: "", lastname: "", token: '' };
            state.error = action.payload;
        },
        getUserProfile(state, action) {
            state.identity.firstname = action.payload.firstname;
            state.identity.lastname = action.payload.lastname;
        },
        clearToken() {
        }
    }
});

export const { signInRequest, signInSuccess, signInError, clearToken, getUserProfile } = authSlice.actions;
export default authSlice.reducer;