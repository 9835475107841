import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { TextField, TextFieldProps } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import { AuthUser } from './../../Services/Auth/auth.model'
import { CustomError } from './../../Services/Models/custom-error.model';
import { passwordResetRequestService } from './../../Services/Auth/auth.service';
import ThankYou from '../../Components/Common/ThankYou';

const PasswordResetRequest: React.FunctionComponent = () => {
  const [params, setParams] = useState(initParams);
  const [errors, setErrors] = useState(initErrors);
  const [validationError, setValidationError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const arrayRef = useRef<Array<HTMLElement | null>>([]);

  const passwordResetRequest = async () => {
    try {
      const result = await passwordResetRequestService(params.email);
      setTimeout(() => {
        setIsLoading(false);
        if (!result.result) { setServerError('Something went wrong. Please try again'); }
        else setSuccess(true);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        if (error instanceof CustomError) {
          setIsLoading(false);
          setServerError(error.message);
        }
      }, 1000);
    }
  }

  const onSubmit = () => {
    if (onValidate()) {
      passwordResetRequest();
      setIsLoading(true);
    }
  }

  const onValidate = () => {

    if (errors.email || !params.email) {

      setValidationError('Please fill in the missing information');

      arrayRef.current[0]?.focus();

      return false;
    };
    setValidationError('');
    return true;
  };

  const onTextFieldChangeHandler = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'email':
        if (value.length < 1) {
          setErrors({ ...errors, email: 'Email required!' })
        } else {
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setErrors({ ...errors, email: 'Email is not valid!' })
          } else {
            setErrors({ ...errors, email: '' })
          }
        }
        break;
      default: {
        break;
      }
    }
    setParams({ ...params, [name]: value });
  };

  return (<>
    {success ? <ThankYou message="Password reset request confirmed. Please check your email for further instructions." /> :
      <>
        {validationError ? (<Alert severity="error">{JSON.stringify(validationError)}</Alert>) : <></>}
        {(serverError && !validationError) ? (
          <Alert severity="error">{JSON.stringify(serverError)} We were unable to send your request please try again</Alert>
        ) : <></>}
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password reset request
        </Typography>
        <TextField
          inputRef={(element) => arrayRef.current[0] = element}
          margin="normal"
          required
          fullWidth
          autoFocus
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          onChange={onTextFieldChangeHandler()}
          color={errors.email ? 'error' : 'primary'}
          helperText={errors.email}
          disabled={isLoading}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onSubmit}
          disabled={isLoading}
        >
          Submit
          {isLoading && <CircularProgress size={30} color="secondary" />}
        </Button>
        <Grid container justifyContent="center">
          <Grid item>
            <Link href="/signin" variant="body2">
              Remembered the password? Sign in
            </Link>
          </Grid>
        </Grid>
      </>
    }
  </>);
}

const initParams = (): { email: AuthUser['email'] } => (
  { email: "" }
)

const initErrors = (): { email: TextFieldProps['helperText']; } => (
  { email: "" }
)

export default PasswordResetRequest;