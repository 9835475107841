import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField, TextFieldProps } from '@mui/material';
import { useAppDispatch, useAppSelector } from './../../Services/Hooks/hooks';
import ShowAlert from './../Common/ShowAlert';
import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/material';
import { AddManualReceiptParams } from '../../Services/Receipt/receipt.types';
import { addManualReceiptEffect } from '../../Services/Receipt/receipt.effects';
import { getCategoriesEffect } from '../../Services/Category/category.effects';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateValidationError } from '@mui/x-date-pickers/models';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const AddManualReceipt: React.FunctionComponent = () => {
  const [params, setParams] = useState(initParams);
  const [validationError, setValidationError] = useState<string>('');
  const [errors, setErrors] = useState(initErrors);
  const arrayRef = useRef<Array<HTMLElement | null>>([]);
  const dispatch = useAppDispatch();
  const receiptState = useAppSelector((state) => state.addManualReceipt);
  const categoryState = useAppSelector((state) => state.category);
  const [show, setShow] = useState<boolean>(false);
  const [errorIssueDate, setErrorIssueDate] = React.useState<DateValidationError | null>(null);

  useEffect(() => {
    dispatch(getCategoriesEffect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValidate = () => {
    let { issueDate, category_id, ...newParams } = params; // exclude date parameter from checking
    const errorList = Object.keys(errors);
    const paramsList = Object.keys(newParams);

    const checkEmpty = (x: Object) => {
      return Object.values(x).every(a => a === null || a === '');
    }

    const checkSomeEmpty = (x: Object) => {
      return Object.values(x).some(a => a === null || a === '');
    }

    if ((!checkEmpty(errors)) || checkSomeEmpty(newParams) || errorIssueDate) {

      const firstParam = paramsList.reduce(
        (a, b) => {
          return newParams[a as keyof typeof newParams].length === 0 ? a : b
        }
      );

      const paramIndex = paramsList.indexOf(firstParam);

      const firstError = errorList.reduce(
        (a, b) => {
          return errors[a as keyof typeof errors] ? a : b
        }
      );

      const errorIndex = errorList.indexOf(firstError);

      const indexFocus = () => {
        if (paramIndex >= errorIndex) {
          return errorIndex;
        } else {
          return paramIndex;
        }
      };

      setValidationError('Please fill in the missing information');

      arrayRef.current[indexFocus()]?.focus();

      return false;
    };
    setValidationError('');
    return true;
  };

  const onSubmit = () => {
    setShow(true);
    if (onValidate()) {
      dispatch(addManualReceiptEffect(params));
    };
  };

  const onUpdate = () => {
    setShow(false);
  }

  const onChangeIssueDate = (newIssueDate: Dayjs | null) => {
    setParams({ ...params, issueDate: newIssueDate });
  }

  const onTextFieldChangeHandler = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'itemName':
        if (value.length < 3) {
          setErrors({ ...errors, itemName: 'item description must be at least 3 characters long!' })
        } else {
          if (!/^[A-Za-z0-9 ,.'-]+$/i.test(value)) {
            setErrors({ ...errors, itemName: 'item description contains invalid characters!' })
          } else {
            setErrors({ ...errors, itemName: '' })
          }
        }
        break;
      case 'totalPrice':
        if (value.length > 10) {
          setErrors({ ...errors, totalPrice: 'are you a billionare?' })
        } else {
          if (!value || value === '.') {
            setErrors({ ...errors, totalPrice: 'price has invalid value!' })
          } else {
            if (!/^[0-9.]+$/i.test(value)) {
              setErrors({ ...errors, totalPrice: 'price contains invalid characters!' })
            } else
              setErrors({ ...errors, totalPrice: '' })
          }
        }
        break;
      default: {
        break;
      }
    }
    setParams({ ...params, [name]: value });
  };

  const onCategoryChange = (event: SelectChangeEvent) => {
    setParams({ ...params, category_id: event.target.value as string });
  };

  return (
    <Box
      sx={{
        m: 4,
        display: 'flex',
        flexDirection: { xs: 'column' },
        maxWidth: 500,
        maxHeight: 'sm',
        alignItems: 'center',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        borderRadius: '12px',
        boxShadow: 1,
        fontWeight: 'bold',
        position: "relative",
        padding: '20px',
        paddingTop: '40px',
      }}>
      {show ?
        <Stack sx={{ width: '100%' }} spacing={8}>
          {validationError ? <ShowAlert onUpdate={onUpdate} show={show} severity="error" message={JSON.stringify(validationError)} /> : (
            <>
              {receiptState.manualReceipt ? <ShowAlert onUpdate={onUpdate} show={show} severity="success" message="Success! Receipt created!" /> : <></>}
              {receiptState.error ? <ShowAlert onUpdate={onUpdate} show={show} severity="error" message={receiptState.error} /> : <></>}
            </>
          )}
        </Stack>
        : <></>}
      <DatePicker
        onError={(error) => setErrorIssueDate(error)}
        format="DD/MM/YYYY"
        label="Issue date"
        value={params.issueDate}
        onChange={onChangeIssueDate}
        slotProps={{
          textField:
            { ...({ fullWidth: true, autoFocus: true }) }
        }}
      />
      <TextField
        inputRef={(element) => arrayRef.current[0] = element}
        margin="normal"
        key="itemName"
        name="itemName"
        onChange={onTextFieldChangeHandler()}
        required
        fullWidth
        id="itemName"
        label="Item description"
        color={errors.itemName ? 'error' : 'primary'}
        helperText={errors.itemName}
        disabled={receiptState.isLoading}
      />

      <FormControl fullWidth sx={{ mt: 1}}>
        <InputLabel id="categoryLabel">Category</InputLabel>
        <Select
          labelId="categoryLabel"
          fullWidth
          key="category"
          id="category"

          label="Category"
          value={params.category_id}
          onChange={onCategoryChange}
        >
          <MenuItem key="0" value="0"><em>No category</em></MenuItem>
          {!categoryState.isLoading && Object.values(categoryState.categories).map((category) => (
            <MenuItem key={category.category_id} value={category.category_id}>{category.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        inputRef={(element) => arrayRef.current[1] = element}
        margin="normal"
        key="totalPrice"
        name="totalPrice"
        onChange={onTextFieldChangeHandler()}
        required
        fullWidth
        id="totalPrice"
        label="Price, EUR"
        color={errors.totalPrice ? 'error' : 'primary'}
        helperText={errors.totalPrice}
        disabled={receiptState.isLoading}
      />
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={onSubmit}
        disabled={receiptState.isLoading}
      >
        Add Receipt
        {receiptState.isLoading && <CircularProgress size={30} color="secondary" />}
      </Button>
    </Box >
  )
};

interface Errors {
  itemName: TextFieldProps['helperText'];
  totalPrice: TextFieldProps['helperText'];
}

const initParams = (): AddManualReceiptParams => ({
  issueDate: dayjs(),
  itemName: "",
  category_id: "0",
  totalPrice: ""
})

const initErrors = (): Errors => ({
  itemName: "",
  totalPrice: ""
})

export default AddManualReceipt;