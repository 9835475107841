import { AppThunk } from '../store';
import { ReceiptParams, Receipts, AddReceiptParams, AddManualReceiptParams } from './receipt.types';
import { CustomError } from './../Models/custom-error.model';
import { receiptRequest, receiptSuccess, receiptError } from './receipt.slice';
import { addReceiptRequest, addReceiptSuccess, addReceiptError } from './add.receipt.slice';
import { addManualReceiptRequest, addManualReceiptSuccess, addManualReceiptError } from './add.manual.receipt.slice';
import { getReceiptByDate, addReceipt, addManualReceipt } from './receipt.service';
import { Receipt, CustomResponse } from "./receipt.model";

export const getReceiptByDateEffect = (from: ReceiptParams['from'], to: ReceiptParams['to']): AppThunk => async (dispatch, getState) => {
  dispatch(receiptRequest());
  try {
    const receipts:Receipts | CustomResponse = await getReceiptByDate(from,to);
    //setTimeout(() => {
      dispatch(receiptSuccess(receipts));
    //}, 1000);
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(receiptError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(receiptError(error));
    }
  }
};

export const addReceiptEffect = (receiptId: AddReceiptParams['receiptId']): AppThunk => async (dispatch, getState) => {
  dispatch(addReceiptRequest());
  try {
    const receipt:Receipt = await addReceipt(receiptId);
    //setTimeout(() => {
      dispatch(addReceiptSuccess(receipt));
    //}, 1000);
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(addReceiptError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(addReceiptError(error));
    }
  }
};

export const addManualReceiptEffect = (manualReceipt: AddManualReceiptParams): AppThunk => async (dispatch, getState) => {
  dispatch(addManualReceiptRequest());
  try {
    const receipt:Receipt = await addManualReceipt(manualReceipt);
    //setTimeout(() => {
      dispatch(addManualReceiptSuccess(receipt));
    //}, 1000);
  } catch (error) {
    if (error instanceof CustomError) {
      return dispatch(addManualReceiptError(error.message));
    } else {
      //console.log('unexpected error: ', error);
      return dispatch(addManualReceiptError(error));
    }
  }
};