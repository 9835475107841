import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright(props: any) {
    return (
      <Typography variant="body2" color="primary.contrastText">
        {'Copyright © '}
        <Link color="inherit" href="https://yanpix.com/">
          Yanpix
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

export default Copyright;