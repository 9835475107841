import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import KeyboardIcon from '@mui/icons-material/Keyboard';
//import EuroIcon from '@mui/icons-material/Euro';
//import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import BarChartIcon from '@mui/icons-material/BarChart';
//import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavigationConfig, ActivePage } from './../../Services/Utils/navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useAppDispatch } from './../../Services/Hooks/hooks';
import { logOutEffect } from './../../Services/Auth/auth.effects';

const MainListItems: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const checkActivePage = NavigationConfig[ActivePage(location)].pageName;
  const dispatch = useAppDispatch();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    route: string
  ) => {
    navigate(route);
  };
  
  const handleListItemLogout = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    dispatch(logOutEffect());
    navigate('/');
  };


  return (
    <>
      <ListItemButton
        selected={checkActivePage === NavigationConfig.Dashboard.pageName}
        onClick={(event) => handleListItemClick(event, NavigationConfig.Dashboard.route)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={NavigationConfig.Dashboard.pageName} />
      </ListItemButton>
      <ListItemButton
        selected={checkActivePage === NavigationConfig.AddReceipt.pageName}
        onClick={(event) => handleListItemClick(event, NavigationConfig.AddReceipt.route)}>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary={NavigationConfig.AddReceipt.pageName} />
      </ListItemButton>
      <ListItemButton
        selected={checkActivePage === NavigationConfig.AddManualReceipt.pageName}
        onClick={(event) => handleListItemClick(event, NavigationConfig.AddManualReceipt.route)}>
        <ListItemIcon>
          <KeyboardIcon />
        </ListItemIcon>
        <ListItemText primary={NavigationConfig.AddManualReceipt.pageName} />
      </ListItemButton>      
      {/*<ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
  </ListItemButton>*/}
      <Divider sx={{ my: 1 }} />
      <ListSubheader component="div" inset>
        Quick reports
      </ListSubheader>
      <ListItemButton
        selected={checkActivePage === NavigationConfig.CurrentMonth.pageName}
        onClick={(event) => handleListItemClick(event, NavigationConfig.CurrentMonth.route)}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={NavigationConfig.CurrentMonth.pageName} />
      </ListItemButton>
      <ListItemButton
        selected={checkActivePage === NavigationConfig.LastQuarter.pageName}
        onClick={(event) => handleListItemClick(event, NavigationConfig.LastQuarter.route)}>
        <ListItemIcon>
          <QueryStatsIcon />
        </ListItemIcon>
        <ListItemText primary={NavigationConfig.LastQuarter.pageName} />
      </ListItemButton>
      <ListItemButton
        selected={checkActivePage === NavigationConfig.CurrentYear.pageName}
        onClick={(event) => handleListItemClick(event, NavigationConfig.CurrentYear.route)}>
        <ListItemIcon>
          <AnalyticsIcon />
        </ListItemIcon>
        <ListItemText primary={NavigationConfig.CurrentYear.pageName} />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton
        onClick={(event) => handleListItemLogout(event)}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>      
    </>
  )
};

export { MainListItems };