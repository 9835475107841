import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import Home from './../Pages/Home/Home';
import SignIn from './../Pages/Auth/SignIn';
import SignUp from './../Pages/Auth/SignUp';
import ThankYou from './../Components/Common/ThankYou';
import ActivateAccount from './../Pages/Auth/ActivateAccount';
import PasswordResetRequest from './../Pages/Auth/PasswordResetRequest';
import PasswordReset from './../Pages/Auth/PasswordReset';
import SideLayout from './../Layouts/SideLayout';
import Dashboard from './../Pages/Dashboard/Dashboard';
import { useAppSelector } from './../Services/Hooks/hooks';
import { NavigationConfig } from './../Types/navigation.types';

const AuthWrapper: React.FunctionComponent = () => {
    const location = useLocation();
    const authData = useAppSelector((state) => state.auth);
    const signUpData = useAppSelector((state) => state.signUp);
    const thankYouMessage = "Thank you for signing up. Please check your inbox for further instructions.";
    const removeTrailingSlash = (str: string) => {
        return str.replace(/\/+$/, '');
    };
    let path = removeTrailingSlash(location.pathname);
    return (
        (authData.isAuthenticated ?
            (<Navigate to="/dashboard" replace state={{ from: location }} />)
            :
            ((path === '/signup') ?
                (
                    (signUpData.isSignedUp) ? <SideLayout children={<ThankYou message={thankYouMessage} />} /> :
                        <SideLayout children={<SignUp />} />
                )
                : <SideLayout children={<SignIn />} />)
        )
    )
};

interface RouteProps {
    redirectPath: string,
    children?: JSX.Element;
};

const ProtectedRoute: React.FunctionComponent<RouteProps> = (props: RouteProps) => {
    const location = useLocation();
    const authData = useAppSelector((state) => state.auth);
    if (!authData.isAuthenticated) {
        return <Navigate to={props.redirectPath} replace state={{ from: location }} />;
    }
    return props.children ? props.children : <Outlet />;
};

const UseTokenWrapper: React.FunctionComponent = () => {
    const location = useLocation();
    
    const getToken = (str: string) => {
        str = str.replace(/\/+$/, '');
        const index = str.indexOf('eyJhbGc'); // token is present
        if (index > 0) {
            return str.substring(index, str.length);
        } else return '';
    };
    
    const token = getToken(location.pathname);

    const component = (): JSX.Element => {
        if (location.pathname.indexOf('activate-account') > 0) {
            return (<SideLayout children={<ActivateAccount token={token} />} />)
        }
        if (location.pathname.indexOf('password-reset') > 0) {
            return (<SideLayout children={<PasswordReset token={token} />} />)
        }
        return (<Navigate to="/" replace state={{ from: location }} />)
    }
    return (
        (!token) ? (<Navigate to="/" replace state={{ from: location }} />) :
            (component())
    )
}

function AppRoutes() {
    return (
        < BrowserRouter >
            <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="/policy" element={<Home />} />
                <Route path="/signin" element={<AuthWrapper />} />
                <Route path="/signup" element={<AuthWrapper />} />
                <Route path="/activate-account/*" element={<UseTokenWrapper />} />
                <Route path="/password-reset-request/*" element={<SideLayout children={<PasswordResetRequest />} />} />
                <Route path="/password-reset/*" element={<UseTokenWrapper />} />
                <Route element={<ProtectedRoute redirectPath="/signin" />}>
                    <Route path={NavigationConfig.Dashboard.route} element={<Dashboard />} />
                    <Route path={NavigationConfig.AddReceipt.route} element={<Dashboard />} />
                    <Route path={NavigationConfig.AddManualReceipt.route} element={<Dashboard />} />
                    <Route path={NavigationConfig.CurrentMonth.route} element={<Dashboard />} />
                    <Route path={NavigationConfig.LastQuarter.route} element={<Dashboard />} />
                    <Route path={NavigationConfig.CurrentYear.route} element={<Dashboard />} />
                </Route>
            </Routes>
        </BrowserRouter >
    )
};

export default AppRoutes;