import axios, { AxiosInstance } from 'axios'
import apiConfig from './vars.config'

const instance: AxiosInstance = axios.create({
  baseURL: apiConfig.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
})

export default instance;