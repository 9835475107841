import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from './../../Services/Hooks/hooks';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { Receipt } from './../../Services/Receipt/receipt.model';

const ReceiptTable: React.FunctionComponent = () => {

  const receipts = useAppSelector((state) => state.receipt.receipts);
  const isLoading = useAppSelector((state) => state.receipt.isLoading);


  const totalSumWithoutVAT = receipts.reduce((prev, current) => {
    return prev + current.taxBaseBasic + current.taxBaseReduced;
  }, 0).toFixed(2);

  const totalSumVAT = receipts.reduce((prev, current) => {
    return prev + current.vatAmountBasic + current.vatAmountReduced;
  }, 0).toFixed(2);

  const totalSummary = receipts.reduce((prev, current) => {
    return prev + current.totalPrice;
  }, 0).toFixed(2);

  return (<>
    {!isLoading &&
      <>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Company name</TableCell>
              <TableCell align="left">Point's address</TableCell>
              <TableCell align="right">Subtotal</TableCell>
              <TableCell align="right">Tax</TableCell>
              <TableCell align="right">Total price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(receipts).map((receipt) => (
              <ReceiptTableRows key={receipt.receiptId} receipt={receipt} />
            ))}
            <TableRow>
              <TableCell />
              <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>Total summary</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{totalSumWithoutVAT + '€'}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{totalSumVAT + '€'}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="right">{totalSummary + '€'}</TableCell>
            </TableRow>
          </TableBody>
        </Table >
      </>}
  </>)
}

const ReceiptTableRows: React.FunctionComponent<ReceiptTableRowProps> = (props: ReceiptTableRowProps) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={props.receipt.receiptId}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{props.receipt.issueDate}</TableCell>
        <TableCell>{props.receipt.organization.name}</TableCell>
        <TableCell>{
          (props.receipt.unit.buildingNumber != null ? props.receipt.unit.buildingNumber + ', ' : '') +
          (props.receipt.unit.streetName != null ? props.receipt.unit.streetName + ', ' : '') +
          (props.receipt.unit.postalCode != null ? props.receipt.unit.postalCode + ', ' : '') +
          (props.receipt.unit.municipality != null ? props.receipt.unit.municipality : '')
        }</TableCell>
        <TableCell align="right" style={{ borderBottom: "none" }}>{`${(props.receipt.taxBaseBasic + props.receipt.taxBaseReduced).toFixed(2)}€`}</TableCell>
        <TableCell align="right" style={{ borderBottom: "none" }}>{`${(props.receipt.vatAmountBasic + props.receipt.vatAmountReduced).toFixed(2)}€`}</TableCell>
        <TableCell align="right" style={{ borderBottom: "none" }}>{`${props.receipt.totalPrice.toFixed(2)}€`}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}></TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Table size="small" aria-label="items">
                <TableHead>
                  <TableRow>
                    <TableCell>Item name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(props.receipt.items).map((item) => (
                    <TableRow key={item.item_id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.category?.name}</TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{item.price + ' €'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

interface ReceiptTableRowProps {
  receipt: Receipt;
}

export default ReceiptTable;