import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

const ShowAlert: React.FunctionComponent<IShowAlertProps> = (props: IShowAlertProps) => {

  const [open, setOpen] = React.useState(true);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    };
    setOpen(false);
      props.onUpdate();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      sx={{ position: "absolute", width: 500 }}
      autoHideDuration={3000}
    >
      <Alert severity={props.severity as AlertColor}>{props.message}</Alert>
    </Snackbar>
  )
}

interface IShowAlertProps {
  onUpdate: () => void;
  show: boolean;
  severity: string;
  message: string;
}

export default ShowAlert;