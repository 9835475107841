import { AxiosRequestConfig } from 'axios';
import instance from "./../../Config/axios.config";
import { CustomError } from './../Models/custom-error.model';
import { signInError } from './../Auth/auth.slice';
import { UserIdentity } from './../Auth/auth.types'


const setUpInterceptor = (store: any) => {

  instance.interceptors.request.use(
    async (config: any | AxiosRequestConfig) => {

      // Do something before request is sent
      const token:UserIdentity["token"] = store.getState().auth.identity.token;
      if (config.headers && token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    }, async (error) => {
      return Promise.reject(error)
    }
  );

  instance.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.code === 'ERR_NETWORK') {
      return Promise.reject(new CustomError(error.message, 500));
    }
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        console.log(error.response.status, "You are not authorized");
        store.dispatch(signInError(error.response.data.message));
      }
      return Promise.reject(new CustomError(error.response.data.message, error.response.status));
    }
    return Promise.reject(error)
  }
  )
}

export default setUpInterceptor;