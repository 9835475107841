import React from 'react';
import { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Totals from './Totals';
import Button from '@mui/material/Button';
import ReceiptTable from './ReceiptTable';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Title from './Title';
import { useAppDispatch, useAppSelector } from './../../Services/Hooks/hooks';
import { getReceiptByDateEffect } from './../../Services/Receipt/receipt.effects';
import { DateValidationError } from '@mui/x-date-pickers/models';
//import Divider from '@mui/material/Divider';
import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/material';
import ShowAlert from './../../Components/Common/ShowAlert';
import SpendingsChart from './SpendingsChart';

const DashboardContent: React.FunctionComponent<DashboardContentProps> = (props: DashboardContentProps) => {

  const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(props.dateFrom);
  const [dateTo, setDateTo] = React.useState<Dayjs | null>(props.dateTo);
  const [errorDateFrom, setErrorDateFrom] = React.useState<DateValidationError | null>(null);
  const [errorDateTo, setErrorDateTo] = React.useState<DateValidationError | null>(null);
  const [show, setShow] = React.useState<boolean>(false);
  const isLoading = useAppSelector((state) => state.receipt.isLoading);
  const errorMessage = useAppSelector((state) => state.receipt.error);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getReceiptByDateEffect(dayjs(props.dateFrom).format('DD-MM-YYYY'), dayjs(props.dateTo).format('DD-MM-YYYY')));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const onChangeDateFrom = (newDateFrom: Dayjs | null) => {
    setDateFrom(newDateFrom);
    if (!dayjs(newDateFrom).isAfter(dayjs(dateTo))) {
      setErrorDateFrom(null);
      setErrorDateTo(null);
    } else {
      setErrorDateFrom("invalidDate");
    }
  }

  const onChangeDateTo = (newDateTo: Dayjs | null) => {
    setDateTo(newDateTo);
    if (!dayjs(newDateTo).isBefore(dayjs(dateFrom))) {
      setErrorDateTo(null);
      setErrorDateFrom(null);
    } else {
      setErrorDateTo("invalidDate");
    }
  }
  const errorDateFromMessage = React.useMemo(() => {
    switch (errorDateFrom) {
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [errorDateFrom]);

  const errorDateToMessage = React.useMemo(() => {
    switch (errorDateTo) {
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [errorDateTo]);

  const onUpdate = () => {
    setShow(false);
  }

  const onSubmit = () => {
    setShow(true);
    if (!(errorDateFrom || errorDateTo)) {
      dispatch(getReceiptByDateEffect(dayjs(dateFrom).format('DD-MM-YYYY'), dayjs(dateTo).format('DD-MM-YYYY')));
    };
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* SearchBar */}
        {!props.hideSearchBar &&
          <>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Container sx={{ marginBottom: 1, marginTop: 1, marginLeft: 0 }}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Date from"
                    value={dateFrom}
                    onChange={onChangeDateFrom}
                    slotProps={{
                      textField:
                        { ...(errorDateFrom ? { error: true, helperText: errorDateFromMessage } : {}) }
                    }}
                    sx={{ marginRight: 5 }}
                  />
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Date to"
                    value={dateTo}
                    onChange={onChangeDateTo}
                    slotProps={{
                      textField:
                        { ...(errorDateTo ? { error: true, helperText: errorDateToMessage } : {}) }
                    }}
                    sx={{ marginRight: 5 }}
                  />
                  <Button type="button" variant="contained"
                    disabled={isLoading}
                    onClick={onSubmit}
                    sx={{ mt: 1 }}>
                    Apply
                    {isLoading && <CircularProgress size={30} color="secondary" />}
                  </Button>
                </Container>
              </Paper>
            </Grid>
          </>
        }


        {!errorMessage && !isLoading &&
          <>
            {/* Spendings chart by categories */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400, }}>
                <SpendingsChart />
              </Paper>
            </Grid>
            {/*Totals*/}
            <Grid item xs={12} md={4} lg={3}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400, }}>
                <Totals />
              </Paper>
            </Grid>
            {/*Spendings chart by Days*/}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400, }}>
                <Chart />
              </Paper>
            </Grid>
          </>
        }
        {/* Receipts */}
        <Grid item xs={12}>
          {props.hideSearchBar && isLoading && <CircularProgress size={30} color="secondary" />}
          {!isLoading &&
            <>
              {errorMessage ?
                <Stack sx={{ width: '100%' }} spacing={8}>
                  <ShowAlert onUpdate={onUpdate} show={show} severity="error" message={errorMessage} />
                </Stack>
                :
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Title>Receipts</Title>
                  <ReceiptTable />
                </Paper>
              }
            </>
          }
        </Grid>
      </Grid>
    </Container>
  )
};

interface DashboardContentProps {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  hideSearchBar?: boolean;
}

export default DashboardContent;