import instance from './../../Config/axios.config';
import { UserIdentity, SignInParams, SignUpParams } from './auth.types';
import { AuthUser } from './auth.model'

export const signInService = async (
  email: SignInParams['email'],
  password: SignInParams['password'],
  rememberMe: SignInParams['rememberMe']
) => {
  const url = `/auth/signin`;
  const params = { email: email, password: password, rememberMe: rememberMe };
  const { data } = await instance.post<UserIdentity>(url, params);
  return data;
};

export const signUpService = async (
  firstname: SignUpParams['firstname'],
  lastname: SignUpParams['lastname'],
  email: SignUpParams['email'],
  password: SignUpParams['password']
) => {
  const url = `/auth/signup`;
  const params = { firstname: firstname, lastname: lastname, email: email, password: password };
  const { data } = await instance.post<boolean>(url, params);
  return data;
};

export const activateAccountService = async (token: UserIdentity['token']) => {
  const url = `/auth/email-verification/${token}`;
  const { data } = await instance.get(url);
  return data;
};

export const passwordResetRequestService = async (email: AuthUser['email']) => {
  const url = `/auth/password-reset-request`;
  const params = { email: email };
  const { data } = await instance.put(url, params);
  return data;
};

export const passwordResetService = async (token: UserIdentity['token'], password: AuthUser['password']) => {
  const url = `/auth/password-reset`;
  const params = { token: token, password: password };
  const { data } = await instance.put(url, params);
  return data;
};

export const getUserProfileService = async () => {
  const url = `/users/profile`;
  const { data } = await instance.get(url);
  return data;
};